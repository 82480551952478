import "./LaserTreatment.scss";
import MedicationIcon from "@mui/icons-material/Medication";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HealingIcon from "@mui/icons-material/Healing";
import BookNow from "../../Components/BookNowButton/BookNow";

export default function LaserTreatment() {
  return (
    <div className="laserTreatment">
      <div className="laserTreatment_hero">
        <h1 className="laserTreatment_title">Laser Treatment</h1>
        <h3 className="laserTreatment_subtitle">
          Precision Care for Lasting Perfection.
        </h3>
      </div>
      <h2 className="laserTreatment_header">About Laser Facials</h2>
      <div className="laserTreatment_section">
        At UHC Beauty & Wellness, we understand
        that radiant skin is not just about how you look, but how you feel. This
        is why we have invested in state-of-the-art laser facial therapy, a
        non-invasive solution that harnesses the precision of advanced
        technology to rejuvenate, restore, and redefine your skin's beauty. Our team of skilled professionals is trained in utilizing
        these technologies to their fullest potential. At UHC Beauty & Wellness,
        we combine these machines' capabilities, our expertise, and your unique
        needs to craft a personalized treatment plan that brings out the best in
        your skin. Choose UHC Beauty & Wellness for a transformative journey to
        radiant, refreshed, and resilient skin.
        <br/>
        <br/>
        PicoPro: Pioneering the next level in skin revitalization, PicoPro
        offers fast and efficient laser treatments. Its advanced capabilities
        target skin imperfections, diminish blemishes, and promote a balanced
        skin tone, helping you achieve a youthful and luminous complexion.
        <br/> 
        <br/>
        Prolenza: Designed with utmost precision, Polenza provides a
        comprehensive solution for various skin concerns. From combating the
        signs of aging to addressing specific skin conditions, its broad
        spectrum ensures that every patient gets a tailor-made treatment
        experience. 
        <br/>
        <br/>
        TempSure: A breakthrough in skin tightening and collagen
        regeneration, TempSure goes beyond the surface to deliver deep thermal
        heating to the skin's foundation layers. This ensures reduced fine
        lines, tightened skin, and a more youthful appearance, all without any
        downtime.
      </div>
      <div className="information">
        <div className="information_section">
          <HealingIcon style={{ fontSize: 50 }} />
          <p className="information_title">Recovery time</p>
          <p className="information_subtitle">Next Day</p>
        </div>
        <div className="information_section">
          <MedicationIcon style={{ fontSize: 50 }} />
          <p className="information_title">Pain Levels</p>
          <p className="information_subtitle">Mild</p>
        </div>
        <div className="information_section">
          <AccessTimeIcon style={{ fontSize: 50 }} />
          <p className="information_title">Treatment Length</p>
          <p className="information_subtitle">90 Minutes</p>
        </div>
      </div>

      <BookNow />
    </div>
  );
}
