import "./ErectileDysfunction.scss";
import MedicationIcon from "@mui/icons-material/Medication";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HealingIcon from "@mui/icons-material/Healing";
import BookNow from "../../Components/BookNowButton/BookNowButton";
import xRayPic from "../../assets/images/xray.jpg";
import muscularMan from "../../assets/images/man.jpg";
import couplehugging from "../../assets/images/couplehugging.jpg";

export default function ErectileDysfunction() {
  return (
    <div className="erectileDysfunction">
      <div className="erectileDysfunction__hero">
        <h1 className="erectileDysfunction__title">
          Erectile Dysfunction Treatment in Edmonton
        </h1>
        <h3 className="erectileDysfunction__subtitle">
          Vitality Rekindled. Confidence Recharged.
        </h3>
      </div>
      <div className="erectileDysfunction__main">
        <div className="erectileDysfunction__container">
          <h2 className="erectileDysfunction__header">
            Understanding Erectile Dysfunction: Causes, Treatment, and
            Prevention
          </h2>
          <div className="erectileDysfunction__information">
            <p className="erectileDysfunction__intro">
              Beginning with a meticulous evaluation, including an in-depth
              medical history and lifestyle analysis, we pinpoint contributors
              to erectile dysfunction. Our primary non-invasive solution: the
              revolutionary low-intensity shockwave therapy (LI-ESWT). This
              treatment fosters blood vessel growth, enhancing circulation.
              Treatment sessions are tailored, factoring in each individual's
              unique condition.
            </p>
            <div className="erectileDysfunction__section">
              <h3 className="erectileDysfunction__subheader">
                What is Erectile Dysfunction?
              </h3>
              <img
                src={muscularMan}
                className="erectileDysfunction__pic"
                alt="Muscular man flexing muscles"
              />
              <p className="erectileDysfunction__content">
                Erectile dysfunction (ED), often referred to as impotence, is a
                common condition. It's characterized by the inability to achieve
                or maintain an erection sufficient for sexual activity. A
                cross-sectional study of 3,921 Canadian men aged 40 to 88
                revealed that nearly half (49.4%) suffer from erectile
                dysfunction (ED), equating to an estimated 3 million Canadian
                men over 40. While it can be a sensitive topic, understanding
                its causes and treatments is essential for those affected.
              </p>
            </div>
          </div>
          <div className="erectileDysfunction__section">
            <h3 className="erectileDysfunction__subheader">
              Causes of Erectile Dysfunction
            </h3>
            <div className="erectileDysfunction__content">
              <ul className="erectileDysfunction__list">
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Physical Causes:</span> Many
                  physical factors can lead to ED. These include age, heart
                  disease, high cholesterol, high blood pressure, diabetes,
                  obesity, and sleep disorders. Certain medications, vaping,
                  tobacco use, and excessive alcohol can also contribute.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Psychological Causes:</span>{" "}
                  Stress, anxiety, depression, and other mental health
                  conditions can lead to ED. Relationship issues, poor
                  communication, and unresolved conflicts can also play a role.
                  Excessive and prolonged exposure to pornographic material can
                  desensitize individuals, leading to decreased arousal in
                  real-life situations.
                </li>
              </ul>
            </div>
          </div>
          <div className="erectileDysfunction__section">
            <img
              src={couplehugging}
              className="erectileDysfunction__pic erectileDysfunction__pic--left"
              alt="Muscular man flexing muscles"
            />
            <h3 className="erectileDysfunction__subheader">
              Treatment Options for Erectile Dysfunction
            </h3>
            <div className="erectileDysfunction__content">
              <ul className="erectileDysfunction__list">
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Oral Medications:</span> Drugs
                  like Viagra, Cialis, and Levitra are commonly prescribed for
                  ED. They enhance the effects of nitric oxide, a natural
                  chemical the body produces to relax muscles in the penis.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">
                    Vacuum Erection Devices:
                  </span>{" "}
                  These are mechanical ways of producing an erection for men who
                  do not want or cannot use drug treatments.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Shockwave Therapy:</span> This
                  is a relatively new and non-invasive treatment for ED. It
                  involves using low-intensity sound waves to increase blood
                  flow to the penis, promoting tissue growth and improving
                  erectile function. Many men have found success with this
                  treatment, especially those who haven't responded well to
                  traditional medications.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Surgery:</span> In cases where
                  other treatments don't work, surgical procedures like penile
                  implants might be an option.
                </li>
              </ul>
            </div>
          </div>
          <div className="erectileDysfunction__section">
            <h3 className="erectileDysfunction__subheader">
              Prevention of Erectile Dysfunction
            </h3>
            <div className="erectileDysfunction__content">
              <ul className="erectileDysfunction__list">
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Regular Check-ups:</span>{" "}
                  Regular medical check-ups can help detect underlying health
                  conditions that might lead to ED.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">
                    Healthy Lifestyle Choices:
                  </span>{" "}
                  Maintaining a healthy weight, regular exercise, a balanced
                  diet, avoiding excessive alcohol, and not smoking or vaping
                  can significantly reduce the risk of ED.
                </li>
                <li className="erectileDysfunction__list--item">
                  <span className="erectileDysfunction__bold">Mental Health:</span> Seeking
                  therapy or counseling can help address psychological causes of
                  ED.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="erectileDysfunction__container erectileDysfunction__treatment">
          <h2 className="erectileDysfunction__header" >
            Shockwave Therapy for Erectile Dysfunction (ED)
          </h2>
          <p className="erectileDysfunction__intro">
            Shockwave therapy, also known as Low Intensity Extracorporeal Shock Wave
            Therapy (LI-ESWT), is a non-invasive procedure that uses low-intensity
            sound waves to increase blood flow to the penis, promoting tissue
            repair and growth.
          </p>
          <div className="erectileDysfunction__section">
            <h3 className="erectileDysfunction__subheader">What is Shockwave Therapy?</h3>
            <p className="erectileDysfunction__content">
              Shockwave therapy uses energy from acoustic waves to induce a
              series of effects in the body. These waves are of a high amplitude
              and short duration. When applied to tissue, they can stimulate
              cellular repair, reduce pain and inflammation, and improve blood
              flow.
            </p>
          </div>
          <div className="erectileDysfunction__section">
            <div className="erectileDysfunction__box">
              <div className="erectileDysfunction__content">
                <h3 className="erectileDysfunction__subheader">
                  How does Shockwave Therapy work for ED?
                </h3>
                <p className="erectileDysfunction__content">
                  For ED, the therapy targets the erectile tissue in the penis.
                  The goal is to:
                </p>
                <ul className="erectileDysfunction__list">
                  <li className="erectileDysfunction__list--item">
                    1. Stimulate the growth of new blood vessels
                    (neovascularization).
                  </li>
                  <li className="erectileDysfunction__list--item">
                    2. Break down micro-plaques in the penile blood vessels.
                  </li>
                  <li className="erectileDysfunction__list--item">
                    3. Activate dormant stem cells in the penile tissue, leading
                    to tissue regeneration.
                  </li>
                </ul>
              </div>
              <img
                src={xRayPic}
                className="erectileDysfunction__pic--xray erectileDysfunction__pic"
                alt="Before and After Shockwave Treatment"
              />
            </div>
          </div>
          <div className="erectileDysfunction__section erectileDysfunction__box">
            <div className="erectileDysfunction__section--left">
              <h3 className="erectileDysfunction__subheader">Procedure:</h3>
              <div className="erectileDysfunction__content">
                <ul className="erectileDysfunction__list">
                  <li className="erectileDysfunction__list--item">
                    1. A gel is applied to the penis to help conduct the
                    shockwaves.
                  </li>
                  <li className="erectileDysfunction__list--item">
                    2. A handheld probe is used to deliver the shockwaves for
                    15mins.
                  </li>
                  <li className="erectileDysfunction__list--item">
                    3. The treatment typically involves several sessions over a
                    few weeks.
                  </li>
                </ul>
              </div>
            </div>
            <div className="erectileDysfunction__section--right">
              <h3 className="erectileDysfunction__subheader">
                Benefits of Shockwave Therapy
              </h3>
              <div className="erectileDysfunction__content">
                <ul className="erectileDysfunction__list">
                  <li className="erectileDysfunction__list--item">
                    <span className="erectileDysfunction__bold">Non-invasive:</span> Unlike
                    surgeries, shockwave therapy doesn't require any incisions,
                    injections, or medications.
                  </li>
                  <li className="erectileDysfunction__list--item">
                    <span className="erectileDysfunction__bold">Painless: </span> The
                    procedure is generally pain-free and doesn't require
                    anesthesia.
                  </li>
                  <li className="erectileDysfunction__list--item">
                    <span className="erectileDysfunction__bold">Effective:</span> Studies
                    suggest significant improvements in erectile function after
                    treatment, and an alternative for those who don't respond to
                    ED medications.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="information">
            <div className="information__container">
              <HealingIcon style={{ fontSize: 50 }} />
              <p className="information__title">Effects Shown Within</p>
              <p className="information__subtitle">3 Weeks - 3 Months</p>
            </div>
            <div className="information__container">
              <MedicationIcon style={{ fontSize: 50 }} />
              <p className="information__title">Pain Levels</p>
              <p className="information__subtitle">None</p>
            </div>
            <div className="information__container">
              <AccessTimeIcon style={{ fontSize: 50 }} />
              <p className="information__title">Treatment Length</p>
              <p className="information__subtitle">15 Mins each</p>
            </div>
          </div>
        </div>

        <div className="erectileDysfunction__container">
          <h2 className="erectileDysfunction__header">
            Viasure by Cynosure: Revolutionizing Aesthetic Treatments
          </h2>
          <div className="erectileDysfunction__cynosure">
            <p className="erectileDysfunction__intro">
              In the ever-evolving world of aesthetic treatments, Cynosure
              stands out as a leading brand, known for its innovative solutions.
              One of its groundbreaking products is Viasure, which has garnered
              attention for its efficacy and state-of-the-art technology.
              Viasure stands unparalleled in treating erectile dysfunction and
              Peyronie's Disease.
            </p>
            <div id="videoContainer">
              <iframe
                width="100%"
                src="https://videopress.com/embed/WNZtKfYy"
                frameBorder="0"
                allowFullScreen
                allow="clipboard-write"
              ></iframe>
              <script src="https://videopress.com/videopress-iframe.js"></script>
            </div>
          </div>

          <div className="erectileDysfunction__section">
            <h3 className="erectileDysfunction__subheader">What is Viasure by Cynosure?</h3>
            <p className="erectileDysfunction__content">
              Viasure is one of Cynosure's advanced aesthetic treatment systems.
              While specific details about Viasure might not be widely
              available, Cynosure's reputation in the industry is that it's
              designed with the highest standards of safety and efficiency in
              mind. This state-of-the-art device, reinforced by extensive
              scientific research, harnesses the power of LI-ESWT to stimulate
              penile blood vessel growth, resulting in improved erectile
              function. Offering minimal side effects, Viasure presents itself
              as the premier non-medicinal alternative for ED treatments.
            </p>
            <h3 className="erectileDysfunction__subheader">
              Why Choose Cynosure's Viasure?
            </h3>
            <p className="erectileDysfunction__content">
              Cynosure has a history of delivering high-quality aesthetic
              treatment systems. Their products, including Viasure, are backed
              by research and are trusted by professionals worldwide. Opting for
              Viasure ensures you're choosing a product from a reputable brand
              with a track record of excellence.
            </p>
          </div>
        </div>
      </div>
      <BookNow />
    </div>
  );
}
