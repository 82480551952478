import React, { useState } from "react";
import "./About.scss";
import { NavLink } from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Modal from "@mui/material/Modal";
import HanPic from "../../assets/images/han.jpg";
import SharonPic from "../../assets/images/sharon.jpg";

const AboutUs = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const employees = [
    {
      name: "Dr. William Han ",
      title: "Medical Director MD CCFP",
      image: HanPic,
      description:
        "Dr. William Han, Medical Director at UHC Beauty & Wellness combines over 30 years of expertise in medical aesthetics with a dedication to holistic beauty and patient safety. Guiding a skilled team, he focuses on natural results that honor individuality. For a journey towards your most radiant self, Dr. Han invites you to explore his range of services and consultations.",
    },
    {
      name: "Sharon Lee",
      title: "Laser Tech",
      image: SharonPic,
      description:
        "Sharon is a dedicated individual with a unique blend of technical expertise and a profound commitment to sexual health. Her extensive knowledge and experience in laser therapy are complemented by her proficiency in technology, creating a seamless fusion of digital innovation and well-being. Through her contributions, she aims to empower individuals to take control of their intimate health, sharing valuable insights and resources to enhance overall quality of life.",
    },
  ];

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent("");
  };

  const body = (
    <div className="modal">
      <p>{modalContent}</p>
      <button onClick={handleCloseModal}>Close</button>
    </div>
  );

  return (
    <div className="about">
      <div className="about_hero">
        <h1 className="about_title">About Us</h1>
        <h3 className="about_subtitle">
          Behind the Scenes: The Heart of Our Mission.
        </h3>
      </div>
      <div className="about_section">
        <h2 className="about_header">Our Story</h2>
        <p className="about_story">
          At the core of our team is a{" "}
          <span>shared passion for excellence,</span> seamlessly interwoven with
          a profound understanding of Asian beauty traditions. Our collective
          expertise, <span>enriched</span> by the timeless wisdom of the East
          and modern techniques, paints a story where global influences meet to
          redefine beauty standards. Our mission, deeply rooted in the nuances
          of Asian beauty philosophies, is to provide unmatched service and{" "}
          <span>innovation.</span> Through collaboration and knowledge-sharing,
          we're committed to crafting a radiant future that celebrates{" "}
          <span>authentic beauty in all its forms</span>.
        </p>
      </div>
      <h2 className="about_header">Our Team</h2>
      <div className="about__section">
        {employees.map((employee, index) => (
          <div className="about_person" key={index}>
            <div className="about_bio">
              <img
                className="about_picture"
                src={employee.image}
                alt={employee.name}
                onClick={() => handleOpenModal(employee.description)}
              />
            </div>
            <h2 className="about_name">{employee.name}</h2>
            <h2 className="about_title">{employee.title}</h2>
          </div>
        ))}
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {body}
      </Modal>
      <div className="about__closing">
        <h1 className="about__closing--title">We are here for you</h1>
        <p className="about__closing--content">
          In our quest for beauty, we blend expertise with artistry, ensuring
          every transformation is both authentic and refined. As seasoned
          professionals, we're not just about procedures; we're about
          partnership. With us, you're embarking on a journey of radiant
          confidence, supported every step of the way.
        </p>
        <div className="about__booking">
          <p className="about__booking--hook">Ready to Book? </p>
          <NavLink to="/booking" className="about__booking--link">
            <p className="about__booking--text">Contact Us</p>
            {<ArrowCircleRightIcon style={{ fontSize: "25px" }} />}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
