import "./Home.scss";
import BookNow from "../../Components/BookNowButton/BookNow";
import AcupunctureIMG from "../../assets/images/acupuncture.jpg";
import BotoxIMG from "../../assets/images/botox.jpg";
import FillerIMG from "../../assets/images/filler.jpg";
import EyelidIMG from "../../assets/images/doubleeyelid.jpg"
import LaserIMG from "../../assets/images/main2.jpg"
import TattooIMG from "../../assets/images/tattoo.jpg"
import RejuvIMG from "../../assets/images/main5.jpg"

import { NavLink } from "react-router-dom";

const offerings = [
  { name: "Double Eyelid Surgery", imageUrl: EyelidIMG  , url:"/doubleeyelidsurgery"},
  { name: "Laser Facial Therapy", imageUrl: LaserIMG , url:"/laser-therapy"},
  { name: "Laser Tattoo Removal", imageUrl: TattooIMG , url:"/tattoo-removal"},

  // ... Add other services similarly
];

export default function Home() {
  return (
    <div className="home">
      <div className="home_hero">
        <div className="home_content">
        <div className="home_hero-overlay"></div>
          <div className="home_content-top">
            <h1 className="home_content-logo">T-Cosmetics</h1>
            <h2 className="home_content-sublogo"> By UHC Beauty and Wellness</h2>
          </div>

          <h3 className="home_content-subtitle">
            Natural Elegance, Refined: Welcome to Your Transformation
          </h3>
        </div>
      </div>
      <div className="services">
        <h2 className="services_header">Our Services</h2>
        <div className="services-container">
          {offerings.map((service, index) => (
            <div key={index} className="services-item">
              <NavLink to={service.url}>
                <img src={service.imageUrl} alt={service.name} className="services-img"/>
                <h3>{service.name}</h3>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <BookNow />
    </div>
  );
}
