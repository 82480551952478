import "./BookNow.scss";
import { NavLink } from "react-router-dom";
import { TypeAnimation } from 'react-type-animation';

export default function BookNowButton() {
  return (
    <div className="bookNow">
      <h1 className="bookNow_title">
        Your <TypeAnimation
        sequence={[
          "Canvas",
          1000,
          "Vision",
          1000,
          "Radiance",
          1000,
          "Renewal",
          1000,
          "Dreams",
          1000,
        ]}
        speed={1}
        repeat={Infinity}
        style={{ fontSize: '1em', color:"white" }}
      />
      , Our Mastery:<br/>
      Secure Your Transformation.
      </h1>
      <div className="bookNow_bottom">
        <div className="bookNow_btn-container">
          <NavLink to="/booking" className="bookNow_link bookNow_button">
            <h3 className="bookNow_btn">Book Now</h3>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
