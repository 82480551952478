import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Header.scss";

export default function Header() {
  const location = useLocation();
  const isServicePage = [
    "/acupuncture",
    "/filler",
    "/botox",
    "/laser-therapy",
    "/tattoo-removal",
    "/vaginal-rejuvenation",
  ].includes(location.pathname);

  const beautyServices = [
    { to: "/doubleeyelidsurgery", label: "Double Eyelid Surgery" },
    { to: "/laser-therapy", label: "Laser Therapy" },
    { to: "/tattoo-removal", label: "Tattoo Removal" },
  ];

  const wellnessServices = [
    { to: "/vaginal-rejuvenation", label: "Vaginal Rejuvenation", external: false },
    { to: "https://mensvitalityservices.com/", label: "Erectile Dysfunction", external: true },
  ];
  

  return (
    <nav className="nav">
      <div className="header">
        <div className="header_left">
          <NavLink to="/" className="header_link">
            <span className="header_logo">T-Cosmetics</span>
            <span className="header_sublogo">By UHC B&W</span>
          </NavLink>
        </div>

        <div className="header_center">
          <NavLink to="/aboutus" className="header_link">
            <span className="header_item">About</span>
          </NavLink>

          <div className={`dropdown`}>
            <span className={`header_link ${isServicePage ? "active" : ""}`}>
              <span className="header_item">Services</span>
            </span>
            <div className="dropdown-content">
              <h3 className="dropdown-heading">Beauty</h3>
              {beautyServices.map((service) => (
                <NavLink
                  key={service.to}
                  to={service.to}
                  className="header_link"
                >
                  <span className="header_item-service">{service.label}</span>
                </NavLink>
              ))}

              <hr className="dropdown-divider" />

              <h3 className="dropdown-heading">Wellness</h3>
              {wellnessServices.map((service) => {
                if (service.to.startsWith("http")) {
                  return (
                    <a
                      key={service.to}
                      href={service.to}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="header_link"
                    >
                      <span className="header_item-service">
                        {service.label}
                      </span>
                    </a>
                  );
                } else {
                  return (
                    <NavLink
                      key={service.to}
                      to={service.to}
                      className="header_link"
                    >
                      <span className="header_item-service">
                        {service.label}
                      </span>
                    </NavLink>
                  );
                }
              })}
            </div>
          </div>

          <NavLink to="/pricing" className="header_link">
            <span className="header_item">Pricing</span>
          </NavLink>

          {/* <NavLink to="/gallery" className="header_link">
            <span className="header_item">Gallery</span>
          </NavLink> */}

          <div className="header_btn-container">
            <NavLink to="/booking" className="header_link header_button">
              <span className="header_book">Book Now</span>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
