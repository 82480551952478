import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('../public/service-worker.js').then(registration => {
      // Removed console.log here
    }).catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
