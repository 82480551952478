import "./DoubleEyelid.scss";
import MedicationIcon from "@mui/icons-material/Medication";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HealingIcon from "@mui/icons-material/Healing";
import BookNow from "../../Components/BookNowButton/BookNow";

export default function DoubleEyelid() {
  return (
    <div className="doubleEyelid">
      <div className="doubleEyelid_hero">
        <h1 className="doubleEyelid_title">Double Eyelid Surgery</h1>
        <h3 className="doubleEyelid_subtitle">
          Transformative Gaze: Designed for You.
        </h3>
      </div>
      <h2 className="doubleEyelid_header">About Double Eyelid Surgery</h2>
      <div className="doubleEyelid_section"> 
        Double eyelid surgery, or blepharoplasty, offers several advantages
        beyond its primary cosmetic appeal. For many, it's not just about
        achieving a certain aesthetic but also enhancing the function of the
        eyes. The procedure can rectify issues like hooding that may obstruct
        vision, making daily tasks more manageable. Additionally, it can
        rejuvenate the eye area by addressing signs of aging, such as sagging or
        puffiness, giving individuals a refreshed, more youthful appearance. On
        a psychological level, many patients report a boost in self-confidence
        and satisfaction after the procedure, as their external appearance
        becomes more aligned with their inner self-image.
      </div>
      <div className="information">
        <div className="information_section">
          <HealingIcon style={{ fontSize: 50 }} />
          <p className="information_title">Recovery time</p>
          <p className="information_subtitle">3 Weeks</p>
        </div>
        <div className="information_section">
          <MedicationIcon style={{ fontSize: 50 }} />
          <p className="information_title">Pain Levels</p>
          <p className="information_subtitle">Mild</p>
        </div>
        <div className="information_section">
          <AccessTimeIcon style={{ fontSize: 50 }} />
          <p className="information_title">Treatment Length</p>
          <p className="information_subtitle">1 Hour</p>
        </div>
      </div>

      <BookNow />
    </div>
  );
}
