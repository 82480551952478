import GalleryFunction from "../../Components/GalleryFunction/GalleryFunction";
import "./Gallery.scss"
import BookNowButton from "../../Components/BookNowButton/BookNow";

export default function Gallery() {
  return (
    <div className="gallery">
      <div className="gallery_hero">
        <h1 className="gallery_title">Gallery</h1>
        <h3 className="gallery_subtitle">
        From Vision to Visuals: Explore Our Work.
        </h3>
      </div>
      <GalleryFunction/>
    </div>
  );
}
