import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_top">
        <div className="footer_column">
          <NavLink to="/" className="footer_link">
            <span className="footer_logo">UHC</span>
            <span className="footer_sublogo">Beauty & Wellness</span>
          </NavLink>
        </div>
        
        <div className="footer_column">
          <h4>Site Map</h4>
          <div className="footer_columns">
            <div className="footer_column-one">
              <NavLink to="/about">About</NavLink>
              <NavLink to="/services">Booking</NavLink>
              <NavLink to="/copyright">Copyright</NavLink>
              <NavLink to="/gallery">Gallery</NavLink>
              <NavLink to="/pricing">Pricing</NavLink>
            </div>
            <div className="footer_column-two">
              <NavLink to="/services">Acupuncture</NavLink>
              <NavLink to="/services">Botox</NavLink>
              <NavLink to="/services">Filler</NavLink>
              <NavLink to="/home">Home</NavLink>

            </div>
          </div>
        </div>
        
        <div className="footer_column">
          <h4>Follow Us</h4>
          <NavLink to="https://www.facebook.com/UHCBeauty">Facebook</NavLink>
          <NavLink to="https://www.instagram.com/UHCBeauty">Instagram</NavLink>
          <NavLink to="https://www.tiktok.com/@UHCBeauty">TikTok</NavLink>
        </div>
        <div className="footer_column">
          <h4>Find Us</h4>
          <p>
            #103-7609 109 St NW,
            <br /> Edmonton, AB
            <br /> T6G 1C3
          </p>
          {/* <ul className="hours">
            <li className="hours_item">
              <p>Monday - Friday</p>
              <p>9am - 5pm</p>
            </li>
            <li className="hours_item">
              <p>Saturday</p>
              <p>10am - 4pm</p>
            </li>
            <li className="hours_item">
              <p>Sundays</p>
              <p>Closed</p>
            </li>
          </ul> */}
        </div>
      </div>
      <div className="footer_bottom">
        <h4> &copy; {new Date().getFullYear()}  <span>UHC Beauty & Health</span> All rights reserved.</h4>
      </div>
    </div>
  );
}

export default Footer;
