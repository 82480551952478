import "./Pricing.scss";
import BookNow from "../../Components/BookNowButton/BookNow";

const services = [
  {
    category: "Acupuncture",
    items: [
      { name: "First Consultation and Treatment", price: "150 for 90mins" },
      { name: "Acupuncture Session", price: "110 for 60mins" },
      {
        name: "Acupuncture(60mins) & Massage(30mins)",
        price: "160 for 90mins",
      },
    ],
  },
  {
    category: "Botox & Filler",
    items: [
      { name: "Xeomin & Dysport", price: "7-10 / unit" },
      { name: "Filler", price: "650 / syringe" },
    ],
  },
  {
    category: "Laser Therapy",
    items: [
      { name: "Picosure Skin Brightening", price: "Contact Us" },
      { name: "Potenza Microneedling", price: "Contact Us" },
      { name: "Tempsure Tightening", price: "Contact Us" },
    ],
  },
  {
    category: "Tattoo Removal",
    items: [{ name: "Laser Tattoo Removal", price: "Contact Us" }],
  },
  {
    category: "Vaginal Rejuvenation",
    items: [{ name: "Vaginal Tightening", price: "Contact Us" }],
  },
];

function ServiceList({ category, items }) {
  return (
    <div className="prices_catagory">
      <h2 className="prices_item">{category}</h2>
      <ul className="prices_lists">
        {items.map((item) => (
          <li className="prices_list" key={item.name}>
            <h2 className="prices_list-item">{item.name}</h2>
            <h2 className="prices_list-prices">{item.price}</h2>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function Prices() {
  return (
    <div className="prices">
      <div className="prices_hero">
        <h1 className="prices_title">Prices</h1>
        <h3 className="prices_subtitle">
          Your Beauty Blueprint.
        </h3>
      </div>
      <div className="prices_container">
        <h1 className="prices_title">Services</h1>
        {services.map((service) => (
          <ServiceList key={service.category} {...service} />
        ))}
      </div>
      <BookNow />
    </div>
  );
}
