import "./Booking.scss";
import React from "react";

const ContactUs = () => {
  return (
    <div className="contact">
      <h1 className="contact__title">Booking & Contact Information</h1>
<div>
          <div className="contact__info">
            <h2 className="contact__name">UHC Beauty & Wellness</h2>
            <div className="contact__location">
                <p>
                  #103 - 7609 109 St NW
                  <br />
                  Edmonton, Alberta
                  <br />
                  T6G 1C3
                  <br />1 (780) 761-9111
                </p>
            </div>
          </div>
    
          <div className="contact__hours">
            <div className="contact__hours--left">
              <h2 className="contact__subtitle">
                Opening
                <br />
                Hours
              </h2>

              <p
                className="contact__email"
              >
                Coming Soon!
              </p>
            </div>
            <div className="contact__hours--right">
              <div className="contact__hours--info">
                <p>Monday - Friday </p>
                <p>9:00am - 5:00pm </p>
              </div>
              <div className="contact__hours--info">
                <p>Saturday </p>
                <p>10:00am - 4:00pm </p>
              </div>
              <div className="contact__hours--info">
                <p>Sunday </p>
                <p>Closed</p>
              </div>
            </div>
          </div>
</div>
    </div>
  );
};

export default ContactUs;