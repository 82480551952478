import "./VaginalTreatment.scss";
import MedicationIcon from "@mui/icons-material/Medication";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HealingIcon from "@mui/icons-material/Healing";
import BookNowButton from "../../Components/BookNowButton/BookNowButton";

export default function VaginalTreatment() {
  return (
    <div className="vaginalTreatment">
      <div className="vaginalTreatment_hero">
        <h1 className="vaginalTreatment_title">Vaginal Rejuvenation</h1>
        <h3 className="vaginalTreatment_subtitle">
          Reawaken Inner Radiance and Vitality.
        </h3>
      </div>
      <h2 className="vaginalTreatment_header">About Vaginal Rejuvenation</h2>
      <div className="vaginalTreatment_section">
        Redefining feminine wellness, our non-surgical vaginal rejuvenation
        treatment is a testament to the harmonious blend of modern science and
        delicate care. Designed to address a range of concerns from vaginal
        laxity, discomfort, to aesthetic desires, this procedure harnesses
        cutting-edge technology to promote collagen production, enhance tissue
        strength, and restore natural suppleness. The non-invasive nature
        ensures minimal discomfort, no downtime, and a quick return to routine
        activities, making it a preferred choice for many.
      </div>
      <div className="information">
        <div className="information_section">
          <HealingIcon style={{ fontSize: 50 }} />
          <p className="information_title">Recovery time</p>
          <p className="information_subtitle">1 Week</p>
        </div>
        <div className="information_section">
          <MedicationIcon style={{ fontSize: 50 }} />
          <p className="information_title">Pain Levels</p>
          <p className="information_subtitle">Mild</p>
        </div>
        <div className="information_section">
          <AccessTimeIcon style={{ fontSize: 50 }} />
          <p className="information_title">Treatment Length</p>
          <p className="information_subtitle">30 Mins</p>
        </div>
      </div>

      <BookNowButton />
    </div>
  );
}
