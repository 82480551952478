import Header from "./Components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import LaserTreatment from "./Pages/LaserTreatment/LaserTreatment";
import ScrollToTop from "./Components/scrollToTop";
import VaginalTreatment from "./Pages/VaginalTreatment/VaginalTreatment";
import Prices from "./Pages/Pricing/Pricing";
import TattooRemoval from "./Pages/TattooRemoval/TattooRemoval";
import AboutUs from "./Pages/About/About";
import Copyright from "./Pages/Copyright/Copyright";
import Home from "./Pages/Home/Home";
import Gallery from "./Pages/Gallery/Gallery";
import ContactUs from "./Pages/Booking/Booking";
import DoubleEyelid from "./Pages/DoubleEyelid/DoubleEyelid";
import MobileNavbar from "./Components/MobileNavbar/MobileNavbar";
import PageNotFound from "./Pages/404/PageNotFound";
import ErectileDysfunction from "./Pages/ErectileDysfunction/ErectileDysfunction";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <MobileNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking" element={<ContactUs/>}/>
          <Route path="/doubleeyelidsurgery" element={<DoubleEyelid/>}/>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/pricing" element={<Prices />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/vaginal-rejuvenation" element={<VaginalTreatment />} />
          <Route path="/laser-therapy" element={<LaserTreatment />} />
          <Route path="/copyright" element={<Copyright />} />
          <Route path="/tattoo-removal" element={<TattooRemoval />} />
          <Route path="/*" element={<PageNotFound/>}/>
          <Route path="/erectile-dysfunction" element={<ErectileDysfunction/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
