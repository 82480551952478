import { NavLink } from "react-router-dom";
import "./PageNotFound.scss";

export default function PageNotFound() {
  return (
    <div className="fourohfour">
      <h1>404: Page not found</h1>
      <NavLink className="fourohfour__link" to="/">
        Click Here to go Back to the Main Page
      </NavLink>
    </div>
  );
}
