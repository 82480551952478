import "./Copyright.scss";

export default function Copyright() {
  return (
    <div className="copyright">
      <h1>Copyright Statement</h1>
      <h4>
        &copy; {new Date().getFullYear()}  <span>UHC Beauty & Health</span> No part
        of this website may be reproduced, distributed, or transmitted in any
        form or by any means without the prior written permission of the
        publisher, except in the case of brief quotations embodied in critical
        reviews and certain other noncommercial uses permitted by copyright
        law. Copyright authority is asserted by the following organizations:
        <br/>
        <br/>
        &copy; United Health Centres
        <br/>
        &copy; Images by <a href="https://www.freepik.com/free-photo/close-up-patient-during-acupuncture-procedure_12066696.htm#query=acupuncture&position=2&from_view=search&track=sph">Freepik</a>

      </h4>
    </div>
  );
}
