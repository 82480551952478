import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [visible, setVisible] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const handleResize = () => {
      setVisible(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mainLinks = [
    { to: "/", label: "Home" },
    { to: "/aboutus", label: "About Us" },
    { to: "/gallery", label: "Gallery" },
    { to: "/pricing", label: "Prices" },
    { to: "/booking", label: "Booking" },
  ];

  const services = [
    { to: "/doubleeyelidsurgery", label: "Double Eyelid Surgery" },
    { to: "https://mensvitalityservices.com/", label: "Erectile Dysfunction" },
    { to: "/laser-therapy", label: "Laser Therapy" },
    { to: "/tattoo-removal", label: "Tattoo Removal" },
  ];

  const socialLinks = [
    { label: "Facebook", to: "https://www.facebook.com/UHCBeauty" },
    { label: "Instagram", to: "https://www.instagram.com/UHCBeauty" },
    { label: "Tiktok", to: "https://www.tiktok.com/@UHCBeauty" },
    // Add more social links as needed
  ];

  if (!visible) return null;

  return (
    <div>
      <AppBar position="static">
        <Toolbar
          style={{ backgroundColor: "black" }}
          sx={{ justifyContent: "space-between", height: "80px" }}
        >
          <Typography
            variant="h6"
            component="h4"
            sx={{
              ml: "1rem",
              fontFamily: "Noto Serif Display, serif",
              textAlign: "center",
              fontWeight: 500,
              fontSize: "1.25rem",
              textTransform: "uppercase",
              letterSpacing: "0.5rem",
              color: "white",
              "& span": {
                fontSize: "0.9rem",
                fontWeight: 300,
                color: "rgb(243, 207, 115)",
                fontFamily: "Helvetica, sans-serif",
              },
            }}
          >
            UHC <span>Beauty & Wellness</span>
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setDrawerOpen(false)}
            style={{
              float: "right",
              margin: "0rem",
              padding: "0.5rem 0.5rem 0rem 0rem",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List style={{ lineHeight: "1rem" }}>
          {mainLinks.map((link) => (
            <ListItem
              button
              key={link.label}
              component={Link}
              to={link.to}
              style={{ padding: "0.25rem 1rem" }}
            >
              <ListItemText primary={link.label} style={{ color: "black" }} />
            </ListItem>
          ))}
          <Divider />
          <ListItem style={{ padding: "0.5rem 1rem" }}>
            <ListItemText
              primary="Services"
              style={{
                color: "rgba(223, 190, 106)",
                fontSize: "1.5rem",
                fontWeight: "bold",
                letterSpacing: "0.3rem",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            />
          </ListItem>
          {services.map((service) =>
            service.to.startsWith("http") ? (
              <ListItem button key={service.label}>
                <a
                  href={service.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-style"
                >
                  <ListItemText
                    primary={service.label}
                    style={{ color: "black" }}
                  />
                </a>
              </ListItem>
            ) : (
              <ListItem
                button
                key={service.label}
                component={Link}
                to={service.to}
                style={{ padding: "0.25rem 1rem" }}
              >
                <ListItemText
                  primary={service.label}
                  style={{ color: "black" }}
                />
              </ListItem>
            )
          )}
          <Divider />
          <ListItem>
            <ListItemText
              primary="Social Media"
              style={{
                color: "rgba(223, 190, 106)",
                fontSize: "1.5rem",
                fontWeight: "bold",
                letterSpacing: "0.3rem",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            />
          </ListItem>
          {socialLinks.map((social) => (
            <ListItem
              button
              key={social.label}
              component={Link}
              to={social.to}
              style={{ padding: "0.25rem 1rem" }}
            >
              <ListItemText primary={social.label} style={{ color: "black" }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default MobileNavbar;
