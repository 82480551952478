import React, { useEffect } from 'react';
import './GalleryFunction.scss';

export default function GalleryFunction() {
    useEffect(() => {
        const scaleAmount = 0.5;

        function scrollZoom() {
            const images = document.querySelectorAll("[data-scroll-zoom]");
            let scrollPosY = 0;
            const adjustedScaleAmount = scaleAmount / 100;

            const observerConfig = {
                rootMargin: "0% 0% 0% 0%",
                threshold: 0
            };

            // Maintain a list of scroll event listeners to later remove them
            const scrollEventListeners = [];

            images.forEach(image => {
                let isVisible = false;

                const onScroll = () => {
                    if (isVisible) {
                        scrollPosY = window.pageYOffset;
                        image.style.transform = `scale(${1 + adjustedScaleAmount * percentageSeen(image)})`;
                    }
                };

                // Push the event listener to our list
                scrollEventListeners.push(onScroll);

                const observer = new IntersectionObserver((elements) => {
                    elements.forEach(element => {
                        isVisible = element.isIntersecting;
                    });
                }, observerConfig);

                observer.observe(image);

                image.style.transform = `scale(${1 + adjustedScaleAmount * percentageSeen(image)})`;
                window.addEventListener("scroll", onScroll);
            });

            function percentageSeen(element) {
                const parent = element.parentNode;
                const viewportHeight = window.innerHeight;
                const scrollY = window.scrollY;
                const elPosY = parent.getBoundingClientRect().top + scrollY;
                const borderHeight = parseFloat(getComputedStyle(parent).getPropertyValue('border-bottom-width')) + parseFloat(getComputedStyle(element).getPropertyValue('border-top-width'));
                const elHeight = parent.offsetHeight + borderHeight;

                if (elPosY > scrollY + viewportHeight) {
                    return 0;
                } else if (elPosY + elHeight < scrollY) {
                    return 100;
                } else {
                    const distance = scrollY + viewportHeight - elPosY;
                    let percentage = distance / ((viewportHeight + elHeight) / 100);
                    percentage = Math.round(percentage);

                    return percentage;
                }
            }

            // Cleanup function
            return () => {
                scrollEventListeners.forEach(listener => window.removeEventListener('scroll', listener));
            };
        }

        scrollZoom();

    }, []);

    return (
        <div className="background">
            <div className="container">
                {Array.from({ length: 6 }).map((_, index) => (
                    <div className="image" key={index}>
                        <img src={`https://source.unsplash.com/random?${index + 1}`} data-scroll-zoom alt={`Service ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}
