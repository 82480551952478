import "./TattooRemoval.scss";
import MedicationIcon from "@mui/icons-material/Medication";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HealingIcon from "@mui/icons-material/Healing";
import BookNow from "../../Components/BookNowButton/BookNow";

export default function TattooRemoval() {
  return (
    <div className="tattooRemoval">
      <div className="tattooRemoval_hero">
        <h1 className="tattooRemoval_title">Tattoo Removal</h1>
        <h3 className="tattooRemoval_subtitle">
          Your Skin's Reset Button: From Bold to Bare.
        </h3>
      </div>
      <h2 className="doubleEyelid_header">About Tattoo Removal </h2>
      <div className="doubleEyelid_section">
        At UHC Beauty & Wellness, we understand that tattoos are deeply personal,
        often holding stories and sentiments. However, we also recognize that
        our past choices might not always align with our evolving self. If
        you're seeking to erase a piece of inked history, our tattoo removal
        service is here to offer a fresh start with minimal discomfort. <br/>
        <br/>
        PicoSure: Stepping into the future of tattoo removal, PicoSure is not only
        revolutionary for its efficacy but also for its commitment to patient
        comfort. Its state-of-the-art technology ensures an almost painless
        experience, setting it apart in the realm of tattoo removal. With
        ultra-fast delivery, PicoSure meticulously shatters tattoo ink into
        minuscule particles, enabling the body to clear them more efficiently.
        Its exceptional precision targets the ink without compromising the
        surrounding skin, minimizing potential scarring. Regardless of the
        tattoo's size, color, or density, PicoSure delivers promising, efficient,
        and most importantly, comfortable results.
      </div>
      <div className="information">
        <div className="information_section">
          <HealingIcon style={{ fontSize: 50 }} />
          <p className="information_title">Recovery time</p>
          <p className="information_subtitle">1 Day</p>
        </div>
        <div className="information_section">
          <MedicationIcon style={{ fontSize: 50 }} />
          <p className="information_title">Pain Levels</p>
          <p className="information_subtitle">Moderate</p>
        </div>
        <div className="information_section">
          <AccessTimeIcon style={{ fontSize: 50 }} />
          <p className="information_title">Treatment Length</p>
          <p className="information_subtitle">15mins - 1Hour</p>
        </div>
      </div>

      <BookNow />
    </div>
  );
}
